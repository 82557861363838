<footer class="background">
  <div class="content">
    <button mat-button (click)="openViessmannWebsite()">© Viessmann Climate Solutions SE</button>
    <div>
      <!--Licenses-->
      <button mat-button (click)="showLicences()">{{'footer-licenses' | translate}}</button>
      <!--Version-->
      {{'global-version' | translate}} {{version}}
    </div>
  </div>
</footer>
